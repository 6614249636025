import SectionContact from "../../components/SectionContact";
import SectionWork from "../../components/SectionWork";
import './styles.css';

const Work = () => {
    return (  
        <>
        <section className="work-hero">
            <h1 className="work-title text-opacity">Projects</h1>
            <p className="work-description text-opacity">Features personal and collaborative works.</p>
            <SectionWork />
        </section>
        <SectionContact />
        </>
    );
}
 
export default Work;