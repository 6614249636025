import Navbar from './components/navbar/Navbar';
import Home from './pages/home/Home';
import About from './pages/about/About';
import Work from './pages/work/Work';
import Contact from './pages/contact/Contact';

import { Routes, Route, useLocation } from 'react-router-dom';
import ScrollToTop from './utils/ScrollToTop';
import TextScroll from './utils/TextScroll';
import PageLoad from './utils/PageLoad';
import SmoothScroll from './utils/SmoothScroll';
import Footer from './components/footer/Footer';

function App() {
	const location = useLocation();

  	return (
    	<>
      		<PageLoad />
      		<ScrollToTop />
      		<TextScroll />
			<SmoothScroll />
      
      		<div id="content">
				<Navbar />
				<Routes location={ location } key={ location.pathname }>
					<Route index element={ <Home /> } />
					<Route path="/about" element={ <About /> } />
					<Route path="/work" element={ <Work /> } />
					<Route path="/contact" element={ <Contact /> } />
				</Routes>
				<Footer />
			</div>
		</>
  );
}

export default App;