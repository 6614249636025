import Lenis from "@studio-freight/lenis";
import { useEffect } from "react";

const SmoothScroll = () => {
	useEffect( () => {
		const lenis = new Lenis()
		function raf(time) {
			lenis.raf(time)
			requestAnimationFrame(raf)
		}
		
		requestAnimationFrame(raf)
	})
}

export default SmoothScroll;