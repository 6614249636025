import SectionContact from "../../components/SectionContact";
import { Link } from "react-router-dom";
import SectionWork from "../../components/SectionWork";
import './styles.css';

const Home = () => {
    return (  
        <>
        <section className="home-hero">
            <img className="home-hero-img" src='https://images.unsplash.com/photo-1548611716-3000815a5803?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D' alt="home-hero" />
            <h1 className="home-hero-title text-opacity">Design. Develop. Deploy.</h1>
        </section>
        <section className="home-about">
            <div className="home-about-content">
                <div className="section-content">
                    <p className="text-opacity">I'm just someone with a passion for web dev. I plan, design, and develop unique and captivating websites.</p>
                </div>
                <Link to="/about" className="link text-opacity">more about me</Link>
            </div>
        </section>
        <section className="home-work">
            <div className="home-work-title">
                <p>Recent Work</p>
            </div>
            <SectionWork count={2} />
            <div className="home-work-view">
                <Link to="/work" className="link text-opacity">view work</Link>
            </div>
        </section>
        <SectionContact />
        </>
    );
}
 
export default Home;