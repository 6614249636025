import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import SplitType from "split-type";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

const TextScroll = () => {
    gsap.registerPlugin(ScrollTrigger);
    const { pathname } = useLocation();

    useEffect(() => {
        // Create a MutationObserver instance
        const observer = new MutationObserver((mutationsList, observer) => {
            for(let mutation of mutationsList) {
                if (mutation.type === 'attributes' && mutation.attributeName === 'data-state') {
                    // Check if the new value is "unlocked"
                    if (mutation.target.getAttribute('data-state') === 'unlocked') {
                        animateTextOnScroll();
                    }
                }
            }
        });

        // Start observing the root element for configured mutations
        const root = document.getElementById('root');
        if (root) {
            observer.observe(root, { attributes: true, attributeFilter: ['data-state'] });
        }

        // Cleanup function to disconnect the observer when the component unmounts
        return () => observer.disconnect();
    }, []); // Empty dependency array means this effect runs once on mount
    
    useEffect(() => {
        if (document.getElementById('root').getAttribute('data-attribute') === 'locked') {
            return;
        }
        animateTextOnScroll();
    }, [pathname]);

    const animateTextOnScroll = () => {
        const textElements = new SplitType(".text-opacity", { types: 'words'});
    
        textElements.words.forEach(word => {
            gsap.to(word, {
                opacity: 1,
                ease: 'none',
                duration: 1,
                scrollTrigger: word
            });
        });
    }
}
 
export default TextScroll;