import SectionContact from "../../components/SectionContact";
import './styles.css';

const About = () => {
    return (  
        <>
        <section className="about-hero">
            <div>
                <h1 className="text-opacity">Creative</h1>
                <h1 className="text-opacity">Designs</h1>
            </div>
            <img className="about-hero-img" src="https://images.unsplash.com/photo-1499951360447-b19be8fe80f5?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="about-hero" />
            <div>
                <h1 className="text-opacity">In High</h1>
                <h1 className="text-opacity">School</h1>
            </div>
        </section>
        <section className="about-content">
            <div>
                <p className="text-opacity">I'm just someone with a passion for web dev. I plan, design, and develop unique and captivating websites.</p>
                <p className="text-opacity">Each project is a chance to learn about new technology and improve current knowledge.</p>
            </div>
            <img className="about-content-img" src='https://images.unsplash.com/photo-1602610411365-76e8c2a88e18?q=80&w=1886&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D' alt="about-content" />
            <p className="text-opacity">Aside from my passion for technology, I enjoy music, playing video games, and exercising. I value balancing my love for tech with leisure activities, continually learning and growing in web development.</p>
        </section>
        <SectionContact />
        </>
    );
}
 
export default About;