const SectionContact = () => {
    return (
        <section className="contact-wrapper">
            <div className="contact-content">
                <h3 className="text-opacity">Want to collaborate? Send me a message through travisdao.t@gmail.com. Check out my work on my <a className="link" href="https://github.com/travis-dao" target="_blank" rel="noreferrer">GitHub</a>.</h3>
            </div>
        </section>
    );
}
 
export default SectionContact;