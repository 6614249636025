import SectionContact from "../../components/SectionContact";
import './styles.css';

const Contact = () => {
    return (
        <div className="contact">
            <SectionContact />
        </div>
    );
}
 
export default Contact;