import gsap from "gsap";
import { useEffect } from "react";

const PageLoad = () => {
    useEffect(() => {
        const duration = 1;
        const delay = 0.2;

        const root = document.getElementById("root");
        root.setAttribute("data-state", "locked");

        var tl = gsap.timeline();
        tl.fromTo(".load-text-wrapper", {yPercent: 0, top: "100%"}, {yPercent: 30, top: "70%", duration: duration, ease: "power4.inOut", delay: delay});
        tl.fromTo(".load-text", {yPercent: 0}, {yPercent: -20, duration: duration, ease: "power4.inOut"}, "<");
        tl.to(".load-text-wrapper", {yPercent: 60, top: "40%", duration: duration, ease: "power4.inOut", delay: delay});
        tl.to(".load-text", {yPercent: -40, duration: duration, ease: "power4.inOut"}, "<");
        tl.to(".load-text-wrapper", {yPercent: 90, top: "10%", duration: duration, ease: "power4.inOut", delay: delay});
        tl.to(".load-text", {yPercent: -60, duration: duration, ease: "power4.inOut"}, "<");
        tl.to(".load-text-wrapper", {yPercent: 100, top: "0%", duration: duration, ease: "power4.inOut", delay: delay});
        tl.to(".load-text", {yPercent: -80, duration: duration, ease: "power4.inOut"}, "<");
        tl.fromTo("#page-load", {yPercent: 0}, {yPercent: -100, duration: duration, ease: "power2.inOut", delay: delay * 2, onStart: () => root.setAttribute("data-state", "unlocked")});
    }, []);

    return (
        <section id="page-load">
            <div className="load-text-area">
                <div className="load-text-title">
                    <h4>Travis Dao</h4>
                    <h4>Portfolio 2024</h4>
                </div>
                <div className="load-text-wrapper">
                    <div className="load-text">
                        <h4>0</h4>
                        <h4>33</h4>
                        <h4>66</h4>
                        <h4>99</h4>
                        <h4>100</h4>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default PageLoad;