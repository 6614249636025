import data from '../ref/Projects.json';

const SectionWork = ({ count = 0 }) => {
    if (count === 0) count = data.length;
    const projects = data.slice(0, count).map((project, key) => {
        return (
            <a className="project" href={ project.link } target='_blank' rel='noreferrer' key={ key }>
                <h3 className="text-opacity">{ project.title }</h3>
                <p className="text-opacity">{ project.description }</p>
            </a>
        );
    });

    return (
        <>
        <div className="projects">{ projects }</div>
        </>
    );
}

export default SectionWork;