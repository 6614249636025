import './styles.css';

const Footer = () => {
    return (
        <section className="footer">
            <div>
                <p className="text-opacity">Travis Dao</p>
                <p className="text-opacity">Web Developer</p>
            </div>
            <div>
                <p className="text-opacity">Design & Development</p>
                <p className="text-opacity">Travis Dao</p>
            </div>
        </section>
    );
}
 
export default Footer;