import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import './styles.css';

const Navbar = () => {
    let links = ["navbar-link-0", "navbar-link-1", "navbar-link-2", "navbar-link-3"];
    let location = useLocation();
    let {pathname} = location;

    useEffect(() => {
        const elements = document.getElementsByClassName("selected");
        while (elements.length > 0) {
           elements[0].classList.remove("selected");
        }
    
        if (pathname === "/about")
        {
            document.getElementById(links[1]).classList.add("selected");
        }
        else if (pathname === "/work")
        {
            document.getElementById(links[2]).classList.add("selected");
        }
        else if (pathname === "/contact")
        {
            document.getElementById(links[3]).classList.add("selected");
        }
    })

    return (  
        <header>
            <Link to="/" id={ links[0] } className="link-home">Travis Dao</Link>
            <div className="links">
                <Link to="/about" id={ links[1] }>About</Link>
                <Link to="/work" id={ links[2] }>Work</Link>
                <Link to="/contact" id={ links[3] }>Contact</Link>
            </div>
        </header>
    );
}
 
export default Navbar;